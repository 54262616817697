<template>
    <info-box :title="title" :icon="icon" v-if="value" :pregnancy="pregnancy">
      <template #list>
        <v-list subheader>
          <v-list-item 
          v-for="(id,name) in value" :key="id"
          :to="{name:'view-file', params: { card: card, file: id }, query: {opened: opened, title: (cardNames ? (title + ' – ' + $t(name.split('_')[1] + 'Side')) : (field ? name.replace(field + '_', '') : name)) }, replace: true}" 
          exact style="min-height:20px" 
          >
            <v-list-item-content class="py-2"><v-list-item-title>
              <template v-if="cardNames">{{ $t(name.split('_')[1] + 'Side') }}</template>
              <template v-else>{{ field ? name.replace(field + '_', '') : name }}</template>
              </v-list-item-title></v-list-item-content>
            <v-list-item-icon><v-icon>$open</v-icon></v-list-item-icon>
          </v-list-item>
        </v-list>
      </template>
    </info-box>
</template>
<script>

import InfoBox from '@/components/InfoBox.vue'

export default {
    name: 'FileList',
    components: {InfoBox},
    props: {value: Object, title: String, icon: String, card: [Number, String], opened: [Number, String], cardNames: Boolean, pregnancy: Boolean, field: String }
}
</script>
