<template>
      <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <template v-slot:activator="{ on, attrs }">
     <v-avatar size="120" v-bind="attrs"
          v-on="on">
      <v-img v-if="downloads[value.profilePicture]" :src="downloads[value.profilePicture]"
      :alt="$t('profilePicture.alt')"
      />
        <v-skeleton-loader v-else-if="value && value.profilePicture" type="avatar" :width="120" :height="120" />
    <v-icon v-else>$person</v-icon>
    </v-avatar>
    </template>
    <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            color="white"
            @click="dialog = false"
          >
            <v-icon>$close</v-icon>
          </v-btn>
          <v-toolbar-title>{{$t('profilePicture.alt')}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="fill-height">
            <v-img v-if="downloads[value.profilePicture]" :src="downloads[value.profilePicture]"
                :alt="$t('profilePicture.alt')"
                max-height="90vh" width="100%"
                contain
            />
        </v-card-text>
    </v-card>
      </v-dialog>
</template>
<script>

import {mapState} from 'vuex';

export default {
    name: 'ProfilePicture',
    props: {value: Object},
    data() {
        return {
            dialog: false
        }
    },
    computed: {
        ...mapState(['downloads'])
    },
    watch: {
        value(val) {
            this.loadImage(val);
        }
    },
    methods: {
        loadImage(val) {
            if(val?.profilePicture) {
                this.$store.dispatch('download', { id: val.profilePicture });
            }
        }
    },
    mounted() {
        this.loadImage(this.value);
    }

}
</script>
<style scoped>
::v-deep .v-skeleton-loader > * {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

::v-deep .v-skeleton-loader .v-skeleton-loader__bone {
  flex-grow: 1;
}
</style>