<template>
  <v-app>
    <v-main>
    <v-container>
    <v-row>
    <v-img :src="logo" class="mb-5" width="100%"></v-img>
    </v-row>
    <v-row justify="center">
    <locale-switch class="mx-auto" :show-label="false" />
    </v-row>
    </v-container>
    <router-view/>
    </v-main>
    <v-snackbar app v-model="notification" :timeout="notificationData.timeout || 10000" :color="notificationData.error ? 'error' : 'success'">
      {{ getNotificationText(notificationData.text) }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="notification = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-footer app    
    padless
    >
    
    <v-card
        flat
        tile
        width="100%"
        class="text-center"
      >
      <v-divider ></v-divider>
  <v-card-text class="my-0 py-0">
    <!--
    <v-row
      justify="center"
      no-gutters
    >
    <v-divider />
    </v-row>
    <v-row
      justify="center"
      no-gutters
    >
    -->
      <v-btn
        color="black"
        text
        rounded
        class="my-0"
        href="https://www.worldemergencycard.com/"
      >
       {{ $t('aboutProject') }}
      </v-btn>
      <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="black"
        text
        rounded
        class="my-0"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t('support') }}
      </v-btn>
      </template>
      <support @close="dialog=false"/>
      
    </v-dialog>
      <!--
      <v-col
        class="pb-2 text-center black--text"
        cols="12"
      >
        {{ new Date().getFullYear() }} — <a href="https://www.manski.cz/" class="white--text">MAŃSKI s.r.o.</a>
      </v-col>
    </v-row>
    -->
    </v-card-text>
    <v-card-text class="black--text mt-0 mb-3 py-0">
          {{ new Date().getFullYear() }} — <a href="https://www.manski.cz/" class="black--text">MAŃSKI s.r.o.</a>
        </v-card-text>

    </v-card>
    </v-footer>
  </v-app>
</template>

<script>

import Support from '@/components/Support'
import LocaleSwitch from '@/components/LocaleSwitch'
import {mapState} from 'vuex'
export default {
  name: 'RochApp',
  components: {
    Support,
    LocaleSwitch
  },
  data() {
    return {
      logo: require('@/assets/logo.png'),
      dialog: false
    }
  },
  computed: {
    notification: {
      get() {
        return this.$store.state.notification;
      },
      set(value) {
        this.$store.commit('notification', value);
      }
    },
    ...mapState(['notificationData'])
  },
  methods: {
    getNotificationText(code) {
      if(!code) {
        return this.$t('errors.unknown');
      }
      switch(code) {
        case 'com': return this.$t('errors.connection');
        case 'auth': return this.$t('errors.auth');
        case 'err': return this.$t('errors.unknown'); 
        default: return code;
      }
    }
  }
}
</script>
