import Vue from 'vue'
import Vuex from 'vuex'
import {getLang} from '@/locale'

Vue.use(Vuex)

const backend = process.env.VUE_APP_BACKEND_URL;
const alhelpBackend = process.env.VUE_APP_ALHELP_URL || 'https://alhelp.worldemergencycard.cz'; // hotfix

const steps = {
  view: {
    get: {
      active: 'view',
      inactive: 'preauthorize'
    }
  },
  preauthorize: {
    post: {
      authorized: 'preactivate',
      unauthorized: 'preauthorize'
    }
  },
  preactivate: {
    post: {
      preactivated: 'activate',
      unauthorized: 'preactivate'
    }
  },
  activate: {
    post: {
      activated: 'update',
      unauthorized: 'preauthorize'
    }
  },
  update: {
    post: {
      updated: 'view',
      unauthorized: 'login'
    },
    get: {
      active: 'update',
      inactive: 'update'
    }
  },
  login: {
    post: {
      verificationRequired: 'verify',
      unauthorized: 'login',
      inactive: 'preactivate'
    }
  },
  verify: {
    post: {
      authorized: 'update',
      unauthorized: 'verify'
    }
  }
}

const changeStep = (step, method, status) => {
  return steps[step][method][status];
}


const getPhone = (phone) => {
  if(phone?.length == 9) {
    return `+420${phone}`;
  } else {
    return phone;
  }
}
export default new Vuex.Store({
  state: {
    loading: false,
    uploading: false,
    downloading: false,
    step: 'view',
    cardNo: null,
    pin: null,
    code: null,
    phone: null,
    // used for initial card setup
    showPregnancy: false,
    card: null,
    original: null,
    downloads: {},
    error: null,
    firstAidTypes: [ 
      'syringe',
      'inhaler',
      'pills',
      'drops',
      'standard'
    ],
    bloodTypes: [
      'A +',
      'A -',
      'B +',
      'B -',
      'AB +',
      'AB -',
      '0 +',
      '0 -'
    ],
    support: {
      phone: process.env.VUE_APP_SUPPORT_PHONE,
      email: process.env.VUE_APP_SUPPORT_EMAIL,
      terms: process.env.VUE_APP_SUPPORT_TERMS
    },
    notification: false,
    notificationData: { error: false,
      text: 'Operation finished sucessfully',
    },
    debug: process.env.VUE_APP_DEBUG == 1,
    locale: getLang(),
  },
  mutations: {
    loading: (state,value) => { state.loading = value },
    uploading: (state,value) => { state.uploading = value },
    downloading: (state,value) => { state.downloading = value },
    step: (state,value) => { state.step = value },
    cardNo: (state,value) => { state.cardNo = value },
    pin: (state, value) => { state.pin = value },
    code: (state,value) => {state.code = value },
    card: (state,value) => { state.card = value },
    original: (state, value) => { state.original = value },
    phone: (state,value) => { state.phone = value },
    showPregnancy: (state, value) => {state.showPregnancy = value },
    downloads: (state,value) => { state.downloads = value },
    error: ( state,value) => { state.error = value },
    notification: (state, value) => { state.notification = value },
    notificationData: (state, value) => { state.notificationData = value },
    locale: (state, value) => { state.locale = value },
  },
  getters: {
    showVerify: (state) => ['activate','verify'].includes(state.step),
    showTerms: (state) => state.step == 'activate',
    showPhone: (state) => state.step == 'preactivate'
  },
  actions: {
    get: async (context, payload) => {
      context.commit('loading', true);
      let response = await fetch(`${backend}?card=${context.state.cardNo}&locale=${context.state.locale}&edit=${!!payload?.edit}`);
      if(!response.ok) {
        context.dispatch('notify', {text: 'com', error: true});
      }
      let data  = await response.json();
      // quick alhelp hack
      if(data.status == 'active' && !process.env.VUE_APP_DEBUG) {
        let servicesr = await fetch(`${alhelpBackend}/.netlify/functions/backend/cards/wec/${context.state.cardNo}`);
        if(!servicesr.ok) {
          context.dispatch('notify', {text: 'com', error: true});
        }
        let services = await servicesr.json();
        // dealing with alhelp bugs - getting rid of null records
        data.card.services = services
        // - getting rid of null records
        .filter(s => !!s)
        // remove duplicates based on id (copilot made this)
        .filter((s, i, self) => self.findIndex(t => t.id === s.id) === i)
        .map(i => i.name).join('|');
      }
      context.commit('card', data.card);
      context.commit('original', data.original);
      context.commit('step', changeStep(context.state.step, 'get', data.status));
      context.commit('loading', false);
    },

    post: async (context) => {
      context.commit('loading', true);
      let response = await fetch(`${backend}?card=${context.state.cardNo}`, {
        method: 'POST',
        // content type cannot be json because that causes preflight request that Apps Script cannot handle
        headers: {
          'Content-Type': 'text/plain'
        },
        body: JSON.stringify({
          action: context.state.step,
          pin: context.state.pin,
          code: context.state.code,
          //devToken: process.env.VUE_APP_DEV_TOKEN,
          phone:  context.state.phone ? `${getPhone(context.state.phone)}` : null,
          // used only during intital setup, later it's ignored
          showPregnancy: context.state.showPregnancy,
          card: context.state.card,
          locale: context.state.locale
        }) 
      });
      if(!response.ok) {
        context.dispatch('notify', {text: 'com', error: true});
      }
      let data  = await response.json();
      if(data.error) {
        context.dispatch('notify', {text: 'err', error: true});
      } else {
        //context.commit('card', data.card);
        context.commit('step', changeStep(context.state.step, 'post', data.status));
        if(data.status == 'unauthorized') {
          context.dispatch('notify', {text: 'auth', error: true});
        }
      }
      
      context.commit('loading', false);
    },

    upload: async (context, payload) => {
      context.commit('uploading', true);
      //https://stackoverflow.com/questions/42217052/how-to-upload-a-file-via-post-dopost-to-a-google-scripts-web-app
      let file;
      if(payload.isBase64) {
        file = payload.file;
      } else {
        var fr = new FileReader();
        file = await new Promise((resolve, reject) => {
        fr.onload = function(e) {
          console.debug('original data url', e.target.result);
          //resolve(e.target.result.replace(/^.*,/, ''));
          resolve(e.target.result);
        }
        fr.onerror = function(e) {
          reject(e);
        }
        fr.readAsDataURL(payload.file);
        });
      }
      let action = 'upload';
      let response = await fetch(`${backend}?card=${context.state.cardNo}`, {
        method: 'POST',
        // content type cannot be json because that causes preflight request that Apps Script cannot handle
        headers: {
          'Content-Type': 'text/plain'
        },
        body: JSON.stringify({
          action,
          field: payload.field,
          pin: context.state.pin,
          code: context.state.code,
          devToken: process.env.VUE_APP_DEV_TOKEN,
          phone:  getPhone(context.state.phone),
          filename: payload.filename,
          filetype: payload.type ||payload.file.type,
          file
        }) 
      });
      if(!response.ok) {
        context.dispatch('notify', {text: 'com', error: true});
      }
      let data  = await response.json();
      if(data.error) {
        context.dispatch('notify', {text: 'err', error: true});
      } else {
        if(data.status == 'unauthorized') {
          context.dispatch('notify', {text: 'auth', error: true});
        } else if(!payload.field) {
          context.commit('card', { ...context.state.card, files: data.files });
        } else {
          context.commit('card', { ...context.state.card, [payload.field]: data[payload.field] });
        }
        
      }
      
      context.commit('uploading', false);
    },

    async deleteFile(context, payload) {
      context.commit('uploading', true);
      let response = await fetch(`${backend}?card=${context.state.cardNo}`, {
        method: 'POST',
        // content type cannot be json because that causes preflight request that Apps Script cannot handle
        headers: {
          'Content-Type': 'text/plain'
        },
        body: JSON.stringify({
          action: 'deleteFile',
          field: payload.field,
          pin: context.state.pin,
          code: context.state.code,
          devToken: process.env.VUE_APP_DEV_TOKEN,
          phone:  getPhone(context.state.phone),
          id: payload.id
        }) 
      });
      if(!response.ok) {
        context.dispatch('notify', {text: 'com', error: true});
      }
      let data  = await response.json();
      if(data.error) {
        context.dispatch('notify', {text: 'err', error: true});
      } else {
        if(!payload.field) {
          context.commit('card', { ...context.state.card, files: data.files });
        } else {
          context.commit('card', { ...context.state.card, [payload.field]: data[payload.field] });
        }
        if(data.status == 'unauthorized') {
          context.dispatch('notify', {text: 'auth', error: true});
        }
      }
      
      context.commit('uploading', false);
    },

    async download(context, payload) {
      context.commit('downloading', true);
      let response = await fetch(`${backend}?file=${payload.id}`);
      if(!response.ok) {
        context.dispatch('notify', {text: 'com', error: true});
      }
      let data  = await response.json();
      
      context.commit('downloads', { ...context.state.downloads, [payload.id]: data.file });
      context.commit('downloading', false);
    },

    notify(context, payload) {
      context.commit('notificationData', payload);
      context.commit('notification', true);
    },
  }
})
