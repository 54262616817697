<template>
    <v-card>
        <v-card-title class="text-h5 primary">
          {{ $t('support') }} </v-card-title>


          <v-list>
            <v-list-item :href="`tel:${support.phone}`">
              <v-list-item-avatar>
                <v-icon
                  color="primary"
                  dark
                >
                $phone
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle>{{ $t('phone') }}</v-list-item-subtitle>
          <v-list-item-title>{{ support.phone }}</v-list-item-title>
        </v-list-item-content>
          </v-list-item>
          <v-list-item :href="`mailto:${support.email}`">
              <v-list-item-avatar>
                <v-icon
                  color="primary"
                  dark
                >
                $email
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle>{{ $t('email') }}</v-list-item-subtitle>
          <v-list-item-title>{{ support.email }}</v-list-item-title>
        </v-list-item-content>
          </v-list-item>
          </v-list>


        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="$emit('close')"
          >
            {{ $t('close') }} </v-btn>
        </v-card-actions>
      </v-card>
</template>
<script>

import {mapState} from 'vuex';

export default {
    name: 'RochSupport',
    computed: {
        ...mapState(['support'])
    }
}
</script>