import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import cs from 'vuetify/lib/locale/cs';
import uk from 'vuetify/lib/locale/uk';
import en from 'vuetify/lib/locale/en';
import { getLang } from '@/locale'

import { mdiCellphone, mdiPill, mdiMagnifyMinusOutline, mdiCity, mdiBell, /* mdiGlasses, */ mdiLock,mdiCardBulleted, mdiAsterisk, 
  mdiLifebuoy, mdiEmail, mdiLogin, mdiKeyLink, mdiAccount, mdiCardAccountMail, mdiAccountHeart, mdiMessage, mdiEye, mdiEyeOff, 
  mdiMedicalBag, mdiOpenInNew, mdiCheck, mdiPhone, mdiPlus, mdiDelete, mdiImageMultipleOutline, mdiCardSearchOutline, mdiTag, 
  mdiNeedle, mdiDoctor, mdiCalendar, mdiVirus, mdiBoxCutter, mdiInformationOutline, mdiWater, mdiCardAccountDetailsOutline, mdiHospitalBoxOutline, mdiBagSuitcaseOutline, mdiUpload, mdiNoteAlertOutline, mdiFaceAgent, mdiHumanPregnant, mdiCalendarHeart, mdiListStatus, mdiHistory, mdiThoughtBubbleOutline, mdiFileMultipleOutline } from '@mdi/js'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { cs, uk, en },
    current: getLang(),
  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      ok: mdiCheck,
      person: mdiAccount,
      name: mdiMessage,
      phone: mdiCellphone,
      email: mdiEmail,
      medication: mdiPill,
      allergies: mdiMagnifyMinusOutline,
      address: mdiCity,
      diseases: mdiBell,
      disease: mdiVirus,
      firstAid: mdiMedicalBag, //mdiGlasses,
      generalPractitioner: mdiDoctor,
      pin: mdiLock,
      card: mdiCardBulleted,
      born: mdiAsterisk,
      help: mdiLifebuoy,
      login: mdiLogin,
      pair: mdiKeyLink,
      contacts: mdiCardAccountMail,
      contactPerson: mdiAccountHeart,
      showPin: mdiEye,
      hidePin: mdiEyeOff,
      openInNew: mdiOpenInNew,
      call: mdiPhone,
      add: mdiPlus,
      delete: mdiDelete,
      gallery: mdiImageMultipleOutline,
      open: mdiCardSearchOutline,
      filename: mdiTag,
      vaccine: mdiNeedle,
      calendar: mdiCalendar,
      surgery: mdiBoxCutter,
      description: mdiInformationOutline,
      bloodType: mdiWater,
      idCard: mdiCardAccountDetailsOutline,
      healthInsuranceCard: mdiHospitalBoxOutline,
      travelInsuranceCard: mdiBagSuitcaseOutline,
      upload: mdiUpload,
      additionalInfo: mdiNoteAlertOutline,
      services: mdiFaceAgent,
      deliveryDate: mdiCalendarHeart,
      pregnancyDetail: mdiHumanPregnant,
      pregnancyFiles: mdiFileMultipleOutline,
      obgyn: mdiDoctor,
      deliveryPlan: mdiListStatus,
      pregnancyHistory: mdiHistory,
      pregnancyPreferences: mdiThoughtBubbleOutline
    }
  },
  theme: {
    themes: {
      //https://coolors.co/c10303-ffee88-00cc99-214f4b-2d3319
      light: {
        primary: '#C10303',
        secondary: '#FFEE88',
        accent: '#00CC99',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
});
