/*
import { uk, cs, en } from 'vuetify/lib/locale'
import customcs from './cs.json'
import customuk from './uk.json'
import customen from './en.json'
export const messages = { cs: { ...customcs, $vuetify: cs }, uk: { ...customuk, $vuetify: uk }, en: { ...customen, $vuetify: en } };
*/
import cs from './cs.json'
import uk from './uk.json'
import en from './en.json'
import sk from './sk.json'
import de from './de.json'
import fr from './fr.json'
import es from './es.json'
import pl from './pl.json'
import hr from './hr.json'


export const messages = { cs, uk, en, sk, pl, de, fr, es, hr };

export const list = [
    {
        code: 'cs',
        flag: 'cz',
        label: 'Česky'
    },
    {
        code: 'en',
        flag: 'gb',
        label: 'English'
    },
    {
        code: 'sk',
        flag: 'sk',
        label: 'Slovenčina'
    },
    {
        code: 'pl',
        flag: 'pl',
        label: 'Polski'
    },
    {
        code: 'de',
        flag: 'de',
        label: 'Deutch'
    },
    {
        code: 'fr',
        flag: 'fr',
        label: 'Français'
    },
    {
        code: 'es',
        flag: 'es',
        label: 'Español'
    },
    {
        code: 'hr',
        flag: 'hr',
        label: 'Hrvatski'
    },
    {
        code: 'uk',
        flag: 'ua',
        label: 'український'
    },
];


export const getLang = () => navigator?.languages?.map(e => e.split('-')[0]).find(e=> !!messages[e]) || 'cs'
