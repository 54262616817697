/* RegExp to find localization messages in source files to generate the file: */
// \$t\('[\w\d.\]+', ?\{[\w :',]+\}? ?\) ?/\* ?[\w \{\}']+ ?\*/

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { messages, getLang } from '@/locale'

Vue.use(VueI18n);

const dateTimeFormats = {
  'uk': {
      short: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        //weekday: 'long',
        hour: 'numeric',
        minute: 'numeric'
      }
  },
  'cs': {
      short: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        //weekday: 'long',
        hour: 'numeric',
        minute: 'numeric'
      }
  },
  'en': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      //weekday: 'long',
      hour: 'numeric',
      minute: 'numeric'
    }
},
}

console.debug('detected locale', getLang());

export default new VueI18n({
    locale: getLang(), // set locale
    fallbackLocale: 'cs',
    messages, // set locale messages
    dateTimeFormats,
    pluralizationRules: {
      'cs': function(choice /*, choicesLength */) {
       // produkt | produkty | produktů 
       if(choice == 1) {
          return 0;
        }
        if(choice > 1 && choice < 5) {
          return 1;
        } else {
          return 2;
        }
      }
    }
  })
