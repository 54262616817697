<template>
    <v-container  v-if="!card" >
    <v-skeleton-loader type="card"/>
    </v-container>
    <v-container v-else>
    
      <v-row justify="center" class="my-3" v-if="card.profilePicture">
        <profile-picture v-model="card.profilePicture" />
      </v-row>
      <h1 class="text-h2 text-center mt-3">{{ card.name }} {{ card.surname }}</h1>
      <h2 class="text-h6 text-center mt-2"><v-icon>$born</v-icon> {{ card.birth }}</h2>
      
    <v-row class="mt-3">
    <template v-if="card.showPregnancy">
      <info-box :title="$t('deliveryDate')" icon="$deliveryDate" pregnancy>
        <span>{{ card.deliveryDate ? $d(new Date(card.deliveryDate), 'short') : $t('notFilledIn')}}</span>
      </info-box>
      <info-box :title="$t('pregnancyDetail')" icon="$pregnancyDetail" pregnancy>
        <span style="white-space: pre;">
          {{ card.pregnancyDetail }}
        </span>
      </info-box>
      <file-list :title="$t('pregnancyFiles')" 
      icon="$pregnancyFiles" 
      v-if="card.pregnancyFiles && Object.keys(card.pregnancyFiles).length > 0"
      v-model="card.pregnancyFiles"
      field="pregnancyFiles"
      :opened="opened" :card="card.no" pregnancy />
      <info-box :title="$t('obgyn')" icon="$obgyn" pregnancy v-if="card.obgynName || card.obgynPhone">
      <template #list>
      <v-list-item><v-list-item-content><v-list-item-subtitle>{{ $t('nameTitle') }}</v-list-item-subtitle><v-list-item-title>{{ card.obgynName }}</v-list-item-title></v-list-item-content></v-list-item>
      <v-list-item><v-list-item-content><v-list-item-subtitle>{{ $t('phone') }}</v-list-item-subtitle><v-list-item-title>{{ card.obgynPhone ? card.obgynPhone : $t('notFilledIn')}}</v-list-item-title></v-list-item-content></v-list-item>
      </template>
      <template #actions v-if="card.obgynPhone">
        <v-btn text color="primary" :href="`tel:${card.obgynPhone}`"><v-icon left>$call</v-icon> {{ $t('call') }}</v-btn>
      </template>
      </info-box>
      <info-box :title="$t('deliveryPlan')" icon="$deliveryPlan" pregnancy v-if="card.deliveryPlan">
        <span style="white-space: pre;">
      {{ card.deliveryPlan }}
      </span>
      </info-box>
      <info-box :title="$t('pregnancyHistory')" icon="$pregnancyHistory" pregnancy v-if="card.pregnancyHistory">
        <span style="white-space: pre;">
      {{ card.pregnancyHistory }}
      </span>
      </info-box>
      <info-box :title="$t('pregnancyPreferences')" icon="$pregnancyPreferences" pregnancy v-if="card.pregnancyPreferences">
        <span style="white-space: pre;">
      {{ card.pregnancyPreferences }}
      </span>
      </info-box>
    </template>
      <info-box :title="$t('contactPerson')" icon="$contactPerson">
      <template #list>
      <v-list subheader>
      <v-list-item><v-list-item-content><v-list-item-subtitle>{{ $t('contactRelation') }}</v-list-item-subtitle><v-list-item-title>{{ card.contactName }}</v-list-item-title></v-list-item-content></v-list-item>
      <v-list-item><v-list-item-content><v-list-item-subtitle>{{ $t('phone') }}</v-list-item-subtitle><v-list-item-title>{{ card.contactPhone }}</v-list-item-title></v-list-item-content></v-list-item>
      </v-list>
      </template>
      <template #actions>
        <v-btn text color="primary" :href="`tel:${card.contactPhone}`"><v-icon left>$call</v-icon> {{ $t('call') }}</v-btn>
      </template>
    </info-box>
    <info-box :title="$t('additionalInfo')" icon="$additionalInfo" v-if="card.additionalInfo">
      <span style="white-space: pre;">
      {{ card.additionalInfo }}
      </span>
    </info-box>
    <info-box :title="$t('firstAid')" icon="$firstAid" v-if="card.firstAidName">
      <template #list>
      <v-list subheader>
      <v-list-item v-if="card.firstAidType"><v-list-item-content><v-list-item-subtitle>{{ $t('firstAidType') }}</v-list-item-subtitle><v-list-item-title>{{ $t(`firstAidTypes.${card.firstAidType}`) }}</v-list-item-title></v-list-item-content></v-list-item>
      <v-list-item><v-list-item-content><v-list-item-subtitle>{{ $t('firstAidName') }}</v-list-item-subtitle><v-list-item-title>{{ card.firstAidName}}</v-list-item-title></v-list-item-content></v-list-item>
      <v-list-item v-if="card.firstAidLink"><v-list-item-content><v-list-item-subtitle>{{ $t('firstAidLink') }}</v-list-item-subtitle><v-list-item-title>{{ card.firstAidLink }}</v-list-item-title></v-list-item-content></v-list-item>
      </v-list>
      </template>
      <template #actions v-if="card.firstAidLink">
        <v-btn text color="primary" :href="firstAidLinkFormat" target="_blank"><v-icon left>$openInNew</v-icon> {{ $t('openLink') }}</v-btn>
      </template>
    </info-box>
    <info-box :title="$t('bloodType')" icon="$bloodType">
      {{ card.bloodType === null ? $t('bloodTypeUnknown') : card.bloodType}}
    </info-box>
    <info-box :title="$t('illnesses')" icon="$diseases">
      <template #list>
        <rows-to-list :data="card.illnesses" />
      </template>
    </info-box>
    <info-box :title="$t('allergies')" icon="$allergies">
        <template #list>
        <rows-to-list :data="card.allergies" />
        </template>
    </info-box>
    <info-box :title="$t('medication')" icon="$medication">
        <template #list>
        <rows-to-list :data="card.medication" />
        </template>
    </info-box>
    <info-box :title="$t('vaccines')" icon="$vaccine" v-if="card.vaccines">
      <template #list>
        <v-list subheader>
        <v-list-item v-for="(v,i) in card.vaccines" :key="i">
          <v-list-item-content>
          <v-list-item-title>{{v.disease}} &ndash; {{v.vaccine ? v.vaccine : $t('vaccineNameUnset') }}</v-list-item-title>
            <v-list-item-subtitle>{{ v.expiry ? $t('vaccineValidUntil') + ' ' + $d(new Date(v.expiry), 'short') : $t('vaccineValidUnset')}}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
      <!--{{ card.city }}-->
        </v-list>
      </template>
    </info-box>

    <info-box :title="$t('surgeries')" icon="$surgery" v-if="card.surgeries">
      <template #list>
        <v-list subheader>
        <v-list-item v-for="(s,i) in card.surgeries" :key="i">
          <v-list-item-content>
          <v-list-item-title>{{ s.description }}</v-list-item-title>
            <v-list-item-subtitle>{{ s.performed ? $t('surgeryPerformed') + ' ' + s.performed : $t('surgeryPerformedUnset')}}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
      <!--{{ card.city }}-->
        </v-list>
      </template>
    </info-box>
    
    <!--
    <info-box :title="$t('gallery')" icon="$gallery" v-if="card.files && Object.keys(card.files).length > 0">
      <template #list>
        <v-list subheader>
          <v-list-item :to="{name:'view-file', params: { card: card.no, file: id }, query: {opened: opened }, replace: true}" 
          exact style="min-height:20px" 
          v-for="(id,name) in card.files" :key="id">
            <v-list-item-content class="py-2"><v-list-item-title>{{ name }}</v-list-item-title></v-list-item-content>
            <v-list-item-icon><v-icon>$open</v-icon></v-list-item-icon>
          </v-list-item>
        </v-list>
      </template>
    </info-box>
    -->
    <file-list :title="$t('medicalDocumentation')" 
      icon="$gallery" 
      v-if="card.files && Object.keys(card.files).length > 0"
      v-model="card.files"
      :opened="opened" :card="card.no" />
    
    <info-box :title="$t('city')" icon="$address">
      <template #list>
        <v-list subheader>
      <v-list-item><v-list-item-content><v-list-item-title>{{ card.city }}</v-list-item-title></v-list-item-content></v-list-item>
      <v-list-item><v-list-item-content><v-list-item-title>{{ $t('countries.' + card.originalLocale) }}</v-list-item-title></v-list-item-content></v-list-item>
      <!--{{ card.city }}-->
        </v-list>
      </template>
    </info-box>
    <info-box :title="$t('generalPractitioner')" icon="$generalPractitioner">
      <template #list>
      <v-list-item><v-list-item-content><v-list-item-subtitle>{{ $t('nameTitle') }}</v-list-item-subtitle><v-list-item-title>{{ card.generalPractitionerName }}</v-list-item-title></v-list-item-content></v-list-item>
      <v-list-item><v-list-item-content><v-list-item-subtitle>{{ $t('phone') }}</v-list-item-subtitle><v-list-item-title>{{ card.generalPractitionerPhone ? card.generalPractitionerPhone : $t('notFilledIn')}}</v-list-item-title></v-list-item-content></v-list-item>
      </template>
      <template #actions v-if="card.generalPractitionerPhone">
        <v-btn text color="primary" :href="`tel:${card.generalPractitionerPhone}`"><v-icon left>$call</v-icon> {{ $t('call') }}</v-btn>
      </template>
    </info-box>
    <file-list :title="$t('idCard')" 
      icon="$gallery" 
      v-if="card.idCard && Object.keys(card.idCard).length > 0"
      v-model="card.idCard"
      :opened="opened" :card="card.id" card-names/>
    <file-list :title="$t('healthInsuranceCard')" 
      icon="$healthInsuranceCard" 
      v-if="card.healthInsuranceCard && Object.keys(card.healthInsuranceCard).length > 0"
      v-model="card.healthInsuranceCard"
      :opened="opened" :card="card.id" card-names/>
    <file-list :title="$t('travelInsuranceCard')" 
      icon="$travelInsuranceCard" 
      v-if="card.travelInsuranceCard && Object.keys(card.travelInsuranceCard).length > 0"
      v-model="card.travelInsuranceCard"
      :opened="opened" :card="card.id" card-names/>
      <info-box :title="$t('services')" icon="$services">
        <template #list>
        <rows-to-list :data="card.services" />
        </template>
    </info-box>
    </v-row>
    
    <v-row no-gutters justify="center" v-if="card.originalLocale != locale">
      <v-switch v-model="showOriginal" :label="$t('showOriginal')" />
    </v-row>
    <v-row no-gutters justify="center" class="mt-3">
      <v-btn text color="primary" :to="{name: 'loginc'}" exact><v-icon left>$edit</v-icon> {{ $t('edit') }}</v-btn>
    </v-row>
    <file-dialog v-model="dialog" :file-content="fileContent" :title="fileTitle" :downloading="downloading"/>
  </v-container>
</template>

<script>

import InfoBox from '@/components/InfoBox'
import RowsToList from '@/components/RowsToList'
import {mixin as VueTimers} from 'vue-timers'
import FileViewer from '@/mixins/FileViewer'
import FileDialog from '@/components/FileDialog'
import FileList from '@/components/FileList'
import ProfilePicture from '@/components/ProfilePicture'
import { mapState } from 'vuex';

let TIMEOUT = process.env.VUE_APP_DEBUG ? 30000000 : 300000;

export default {
  name: 'RochShow',
  components: {
    InfoBox,
    RowsToList,
    FileDialog,
    FileList,
    ProfilePicture
  },
  mixins: [VueTimers, FileViewer],
  data() {
    return {
      dialog: false,
      opened: new Date().valueOf(),
      showOriginal: false
    }
  },
  computed: {
    firstAidLinkFormat() {
      if(!this.card?.firstAidLink) {
        return '';
      }
      if(this.card?.firstAidLink.indexOf('http') == 0) {
        return this.card?.firstAidLink;
      }
      return `https://${this.card?.firstAidLink}`
    },
    card() {
      return this.showOriginal ? this.ocard : this.tcard;
    },
    ...mapState({step: 'step', tcard: 'card', ocard: 'original', locale: 'locale'})
  },
  watch: {
    step(newValue) {
      if(newValue == 'preauthorize') {
        this.$router.push({ name: 'loginc' });
      }
    },
    locale() {
      // obtain translated values
      console.debug('locale change detected');
      this.$store.dispatch('get');
    }
  },
  mounted() {
    let card = this.$route.params.card;
    if(!this.$route.query.opened) {
      this.$router.replace({name: 'view', query: { opened: this.opened}});
    } else {
      this.opened = this.$route.query.opened;
    }
    if(!card) {
      this.$router.push({ name: 'login'});
    } else {
      this.$store.commit('cardNo', card);
    }
    this.$store.dispatch('get');
  },
  timers: {
    invalidate: { time: 1000, autostart: true, repeat: true, immediate: true }
  },
  methods: {
    invalidate() {
      console.debug(`Remaining ${this.opened - ((new Date()).valueOf() - TIMEOUT)} ms to invalidate`);
      if(this.opened && this.opened < ((new Date()).valueOf() - TIMEOUT)) {
        this.$router.replace({name: 'timeout'});
      }
    }
  }
}
</script>
