<template>
    <v-col cols="12" md="6" lg="4" xl="3">
    <v-card class="fill-height d-flex flex-column justify-start">
        <v-card-title class="black--text">
          <v-icon :color="pregnancy ? '#ff8fab' : 'black'" class="mr-3">{{ icon }}</v-icon>{{ title }}
        </v-card-title>
        <v-card-text v-if="hasSlot()">
          <slot></slot>
        </v-card-text>
        <slot name="list"></slot>
        <v-spacer />
        
        <v-card-actions v-if="hasSlot('actions')">
          <slot name="actions"></slot>
        </v-card-actions>
      </v-card>
    </v-col>
</template>
<script>

export default {
    name: 'InfoBox',
    props: {
      title: { type: String, required: true },
      icon: { type: String, required: true },
      pregnancy: { type: Boolean, default: false }
    },
    computed: {
    },
    methods: {
      hasSlot (name = 'default') {
        return !!this.$slots[ name ] || !!this.$scopedSlots[ name ];
      }
    }
}
</script>