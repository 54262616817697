<template>
    <v-list subheader>
      <v-list-item style="min-height:20px" v-for="(r,i) in rows" :key="i">
        <v-list-item-content class="py-2"><v-list-item-title>{{ r }}</v-list-item-title></v-list-item-content>
        </v-list-item>
    </v-list>
</template>
<script>

export default {
    name: 'RowsToList',
    props: {
      data: { type: String, default: '' }
    },
    computed: {
      rows() {
        return this.data?.split(/\n|\|/).map(r=> r.trim()) || []
      }
    },
    methods: {
      
    }
}
</script>