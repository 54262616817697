import {mapState} from 'vuex'

export default {
    data() {
        return {
            dialog: false,
            file: null
        }
    },
    computed: {
        fileContent() {
            return this.file ? this.downloads[this.file] : null;
        },
        fileTitle() {
          return this.$route.query.title ?? this.$t('fileViewer.defaultTitle');
        },
        ...mapState(['downloading','uploading','downloads'])
    },
    watch: {
        $route(route) {
            if(route.params.file) {
              this.file = route.params.file;
              this.dialog = true;
              if(!this.fileContent) {
                this.$store.dispatch('download', { id: this.file });
              }
            } else {
              this.dialog = false;
            }
          },
          dialog(value) {
            if(!value) {
              this.$router.replace({ name: this.baseRoute || 'view', query: {opened: this.opened } });
            }
          }
    }
}