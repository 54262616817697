import Vue from 'vue'
import VueRouter from 'vue-router'
import View from '../views/Show.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/login/:card',
    name: 'loginc',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/edit/:card',
    name: 'edit',
    component: () => import(/* webpackChunkName: "edit" */ '../views/Edit.vue')
  },
  {
    path: '/edit/:card/:file',
    name: 'edit-file',
    component: () => import(/* webpackChunkName: "edit" */ '../views/Edit.vue')
  },
  {
    path: '/timeout',
    name: 'timeout',
    component: () => import(/* webpackChunkName: "timeout" */ '../views/Timeout.vue')
  },
  {
    path: '/:card',
    name: 'view',
    component: View
  },
  {
    path: '/:card/:file',
    name: 'view-file',
    component: View
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
