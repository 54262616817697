<template>
      <v-dialog fullscreen transition="dialog-bottom-transition" v-model="dialog">
      <v-card tile :loading="downloading">
        <!--
        <v-card-actions>
          <v-spacer></v-spacer>
          
          <v-btn icon @click="dialog=false"><v-icon>$cancel</v-icon></v-btn>
        </v-card-actions>
        -->
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            color="white"
            @click="dialog = false"
          >
            <v-icon>$close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>
        <template v-if="fileContent && isPDF">
          <vue-pdf-embed :source="fileContent" />
        </template>
        <v-img v-else-if="fileContent" :src="fileContent" />
        <v-skeleton-loader v-else type="image" />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog=false"
          >
            {{$t('close')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
    name: 'FileDialog',
    props: {
        fileContent: String,
        title: String,
        value: Boolean,
        downloading: Boolean
    },
    components: {
      VuePdfEmbed
    },
    data() {
      return {
        currentPage: 0,
        pageCount: 0
      }
    }, 
    computed: {
        dialog: {
            get() { return this.value },
            set(val) { this.$emit('input', val) }
        },
        isPDF() {
          return this.fileContent?.startsWith('data:application/pdf')
        }
    }
}
</script>

<style>

</style>